import { Guid } from 'guid-typescript';
import type { PropsWithChildren } from 'react';
import { forwardRef } from 'react';
import type { LinkProps } from 'react-router';
import { Link } from 'react-router';

import { ApplicationIcon } from '~/components/application-icons';
import { Button } from '~/components/ui/button';

export type CreateButtonProps = Omit<LinkProps, 'to' | 'relative'>;
export const CreateButton = forwardRef<HTMLAnchorElement, PropsWithChildren<CreateButtonProps>>(
  ({ children, ...props }, ref) => (
    <Link data-testid='create' ref={ref} to={Guid.EMPTY} relative='path' {...props}>
      <Button icon={<ApplicationIcon icon='add' />}>{children}</Button>
    </Link>
  )
);
CreateButton.displayName = 'CreateButton';
